<template>
  <b-modal
      id="modal-edit-form-item"
      ref="modal-edit-form-item"
      footer-class="flex-nowrap"
      no-close-on-backdrop
      centered
  >
    <template #modal-header>
      <h3>{{ $t('modals.editFormItem.title') }}</h3>
    </template>

    <template #default>
      <modal-choose-name-and-description
          :nameProp="formItemName"
          :descriptionProp="formItemDescription"
          :namePlaceholder="$t('modals.createFormItem.namePlaceholder')"
          :descriptionPlaceholder="$t('modals.createFormItem.descriptionPlaceholder')"
          @update-fields="updateFields"
          @enter-pressed="editFormItem"
      ></modal-choose-name-and-description>
    </template>

    <template #modal-footer="{ close }">
      <b-button
          class="p-3 border-right-light-grey"
          block
          @click="close()"
      >
        {{ $t('buttons.cancel') }}
      </b-button>
      <b-button
          :disabled="!isFormItemNameSet"
          variant="primary"
          class="p-3"
          block
          @click="editFormItem"
      >
        {{ $t('modals.editFormItem.validate') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import UpdateForm from '@/graphQlQueries/mutations/forms/updateForm';

export default {
  name: 'ModalEditFormItem',
  props: ['formItem'],
  components: {
    ModalChooseNameAndDescription: () => import('@/components/modals/ModalChooseNameAndDescription.vue'),
  },
  data() {
    return {
      formItemName: this.formItem ? this.formItem.name : null,
      formItemDescription: this.formItem ? this.formItem.description : null,
    };
  },
  computed: {
    isFormItemNameSet() {
      return !!this.formItemName
          && (this.formItem.name !== this.formItemName
              || this.formItem.description !== this.formItemDescription);
    },
  },
  methods: {
    updateFields(key, value) {
      if (key === 'name') {
        this.formItemName = value.trim();
      } else {
        this.formItemDescription = value.trim();
      }
    },
    async createFormItem() {
      if (this.isFormItemNameSet) {
        this.$emit('create-form-item', { name: this.formItemName, desc: this.formItemDescription });
        this.$refs['modal-add-new-form-item'].hide();
      }
    },
    async editFormItem() {
      if (this.isFormItemNameSet) {
        try {
          await this.$apollo.mutate({
            mutation: UpdateForm,
            variables: {
              obj: {
                form_id: this.formItem.form_id,
                name: this.formItemName,
                description: this.formItemDescription,
                form_data: null,
              },
            },
          });
          this.$emit('editFormItem', { name: this.formItemName, desc: this.formItemDescription });
          this.$refs['modal-edit-form-item'].hide();
        } catch (ex) {
          console.error(ex);
        }
      }
    },
  },
};
</script>

<style scoped>

</style>
